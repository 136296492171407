import "./partners.scss"

import React from "react"

import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

import loadable from "@loadable/component"

// import Banner from "../components/partners/Banner"
// import PartnerList from "../components/partners/PartnerList"
// import Question from "../components/partners/Question"
// import Slogan from "../components/Slogan"
import Layout from "../components/Layout"
import {
  getAllLocales,
  getBackgroundImage,
  getCurrentLanguagePage,
  getGatsbyImage,
} from "../plugins/common"

const Banner = loadable(() => import("../components/partners/Banner"))
const PartnerList = loadable(() => import("../components/partners/PartnerList"))
const Question = loadable(() => import("../components/partners/Question"))
const Slogan = loadable(() => import("../components/Slogan"))

const partnersHeaderLeftEmpty =
  "../images/partners/partnersHeaderLeftEmpty.webp"
const partnersHeaderRightFull =
  "../images/partners/partnersHeaderRightFull.webp"
const partnersHeaderRightEmpty =
  "../images/partners/partnersHeaderRightEmpty.webp"

const PartnersPage = ({ pageContext, location }) => {
  const { node: data, general, form } = pageContext
  const aleaToken = general.aleaToken
  const headerImage = getGatsbyImage(data.headerImage.image[0])
  const languageOptions = general.allStrapiLanguages?.nodes ?? "en"
  const lang = getCurrentLanguagePage(location, languageOptions)
  const ogImage = getBackgroundImage(data.headerImage.image[0])
  const enableFooterForm = data.footerForm?.enableFooter
  const footerForm = {
    ...data.footerForm,
    ...form.freeQuote,
  }
  return (
    <Layout
      props={pageContext.general}
      meta={data.meta}
      localizations={getAllLocales(data.localizations, data.locale)}
      location={location}
      lang={lang}
      ogImage={ogImage}
      hideNewsletter={true}
      token={aleaToken}
      footerForm={enableFooterForm ? footerForm : null}
    >
      <div className="overflow-hidden">
        <Slogan props={data.headerImage} lang={lang}>
          <div id="left" className="partnerHeader g-0 position-relative">
            <StaticImage
              sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
              className="leftEmpty position-absolute"
              src={partnersHeaderLeftEmpty}
              alt=""
              layout="constrained"
            />
          </div>
          <div id="right" className="partnerHeader g-0 position-relative">
            <StaticImage
              sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
              className="rightFull position-absolute"
              src={partnersHeaderRightFull}
              alt=""
              layout="constrained"
            />
            <StaticImage
              sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
              className="rightEmpty position-absolute"
              src={partnersHeaderRightEmpty}
              alt=""
              layout="constrained"
            />

            <div className="imageZoom">
              <GatsbyImage
                sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                className="image imageAni position-absolute"
                image={headerImage}
                alt="alea"
              />
            </div>
          </div>
        </Slogan>
        <PartnerList
          props={data.partners}
          label={data.loadMoreLabel}
          lang={lang}
        />
        <Banner props={data.banner} lang={lang} />
        <Question props={data.aboutPartners} lang={lang} />
      </div>
    </Layout>
  )
}
export default PartnersPage
